import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        ClientWidth_number: document.documentElement.clientWidth
    },
    actions: {
        set_ClientWidth(context, params) {
            context.commit('set_ClientWidth', params);
        }
    },
    mutations: {
        set_ClientWidth(state, params) {
            state.ClientWidth_number = params;
        }
    }
})
