<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        }
    }
</script>

<style>
    #app {
        overflow-x: hidden;
    }

    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>
