import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({

    mode:'hash',

    routes:[
        {
            path:'/',
            redirect:'/home'
        },

        {
            path:'*',
            redirect:'/404',

        },

        {
            path:'/',
            component: () => import('../components/cernet.vue'),

            children:[
                {
                    path:'home',
                    component: () => import('../components/page/home.vue'),
                    //首页
                },

                {
                    path:'404',
                    component: () => import('../components/page/404.vue'),
                    //404错误页面
                },

                {
                    path:'test.html',
                    component: () => import('../components/page/safetyTest.vue'),
                    //安全众测
                },

                {
                    path:'consult.html',
                    component: () => import('../components/page/safetyConsult.vue'),
                    //安全咨询
                },
                {
                    path:'protect.html',
                    component: () => import('../components/page/safetyFence.vue'),
                    //安全防护
                },
                {
                    path:'management.html',
                    component: () => import('../components/page/securityManagement.vue'),
                    //资产管理
                },
                {
                    path:'practice.html',
                    component: () => import('../components/page/securityPractice.vue'),
                    //实战演习
                },
                {
                    path:'about.html',
                    component: () => import('../components/page/aboutUs.vue'),
                    //关于我们
                },
                {
                    path:'vss.html',
                    component: () => import('../components/page/check.vue'),
                    //威胁检测
                },
                {
                    path:'ad.html',
                    component: () => import('../components/page/introduce.vue'),
                    //威胁管控
                },
            ]
        }
    ]
})
