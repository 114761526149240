import Vue from 'vue'
import App from './App.vue'
import less from 'less'
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index'

Vue.use(less);
Vue.config.productionTip = false;
Vue.use(element);
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
